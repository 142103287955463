<template>
  <AView
    :value="value"
    :actions="actions"
    :hasFilters="!!filter.search"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    :payload="{
      from: value,
    }"
  >
    <template v-slot:content="{}">
      <v-row>
        <v-col cols="12">
          <FormSection
            :label="'Experience Records'"
            :icon="value.view.display.icon"
            :actions="actions"
            :editable="!!actions.length"
            @action="(action) => action.handler({ from: value })"
            right
          ></FormSection>

          <!-- <span>Here you're able to find experience records for different technologies, compliances, certificates, actions practical usage. So if you need some information or evidence for the client about ADAAS experience you're able to find it here. <br> Please note that some records may be connected to terms like AWS is a term but AWS Organization management is an experience record.</span> -->
        </v-col>
      </v-row>

      <v-row class="scroll-row d-flex flex-grow-1">
        <v-col cols="12" class="pa-0 ma-0 d-flex flex-column flex-grow-1">
         <v-virtual-scroll
            class="pa-0 ma-0"
            :bench="5"
            :items="experienceNotes"
            height="630"
            item-height="360"
            @scroll="onScroll"
          >
            <template v-slot:default="{ item }">
              <ExperienceNoteItemVue
                class="ma-2"
                :value="item"
                :style="{
                  height: '350px',
                }"
                :active="activeExperienceNoteId === item.id"
                :key="item.id"
                @onOpen="onSelect"
                @click="onSelect"
                @mouseover="onHover"
                @mouseleave="() => {}"
              ></ExperienceNoteItemVue>
            </template>
          </v-virtual-scroll>
        </v-col>
      </v-row>

      <Pagination
        :value="pagination"
        :total="experienceNotes.length"
        :show="showMore"
        @onLoadMore="
          $store.dispatch('ExperienceNotesStore/GetExperienceNotesList', {
            next: true,
          })
        "
      ></Pagination>
    </template>

    <template v-slot:actions="{}">
      <QuestionsFilter v-model="filter"> </QuestionsFilter>
    </template>
  </AView>
</template>
    
    
    
  <script>
import { mapState } from "vuex";
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import FormSection from "../../../../../../components/wad/atoms/common/FormSection.vue";
import Pagination from "../../../../../../components/wad/atoms/common/Pagination.vue";
import QuestionsFilter from "../../../../../../components/wad/molecules/knowledgeBase/questions/QuestionsFilter.vue";
import ExperienceNoteItemVue from "../../../../../../components/wad/organisms/knowledgeBase/experienceNotes/ExperienceNoteItem.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { EXPERIENCE_NOTE_BUTTONS } from "../../constants/actions.gh";

export default {
  name: "ExperienceNotes",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    FormSection,
    ExperienceNoteItemVue,
    Pagination,
    QuestionsFilter,
  },
  computed: {
    ...mapState("ExperienceNotesStore", [
      "experienceNotes",
      "activeExperienceNoteId",
      "pagination",
    ]),
    actions: function () {
      return PermissionsHelper.getActions(
        EXPERIENCE_NOTE_BUTTONS.CREATE_NEW,
        this.value.permissions
      );
    },
  },
   created() {
    this.$store.dispatch("ExperienceNotesStore/GetExperienceNotesList", {
      reset: true,
    });
  },
  data() {
    return {
      filter: {},
      showMore: false,
    };
  },
  methods: {
    onHover(value) {
      this.$store.commit("ExperienceNotesStore/setActiveId", value.id);
    },
    onSelect(experienceNote) {
      this.$store.dispatch("ExperienceNotesStore/SetActiveExperienceNote", {
        from: this.value,
        experienceNote,
      });
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("END");
        this.showMore = true;
      } else {
        this.showMore = false;
      }
    },
  },
  watch: {
    filter: {
      handler(newVal) {
        this.$store.dispatch("ExperienceNotesStore/GetExperienceNotesList", {
          filter: newVal,
          reset: true,
        });
      },
      deep: true,
    },
  },
};
</script>
    
    
    
<style scoped lang="scss" >

.scroll-row {
  height: calc(100% - 110px);
}
</style>